import "./githubPersonal.module.css";

const GitHubPersonal = () => {
	return (
		<div>
			<img
				className="githubpersonal-icon"
				src="./www/headshot.jpg"
			/>
		</div>
	);
};

export default GitHubPersonal;
