import IconBarIconWrapper from "./IconBarIconWrapper/IconBarIconWrapper";

const ExplorerIcon = () => {
	return (
		<IconBarIconWrapper explorer={true}>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
			/>
		</IconBarIconWrapper>
	);
};

export default ExplorerIcon;
