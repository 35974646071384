import "./rightblob.module.css"

const RightBlob = () => {
    return (
        <div className="rightblob-container">
            <div className="rightblob-title">LinkedIn News</div>
            <ul className="rightblob-posts">
                <li>something something economy</li>
                <li>blah blah pandemic</li>
                <li>please wear a mask</li>
                <li>crooked CEO turns new leaf</li>
                <li>leaf turns into a CEO</li>
            </ul>
        </div>
    )
}

export default RightBlob;