import IconBarIconWrapper from "./IconBarIconWrapper/IconBarIconWrapper";

const ExtensionsButActuallyAPuzzleIcon = () => {
	return (
		<IconBarIconWrapper>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z"
			/>
		</IconBarIconWrapper>
	);
};

export default ExtensionsButActuallyAPuzzleIcon;
