import IconWrapper from "../../../utilityComponents/IconWrapper/IconWrapper";

const ReposIcon = () => {
	return (
		<IconWrapper>
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
			/>
		</IconWrapper>
	);
};

export default ReposIcon;
