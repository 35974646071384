// import "./codeeditor.module.css"
import CodeContent from "./CodeLine/CodeContent"

const CodeEditor = () => {
    return (
        <div>
            <CodeContent />
        </div>
    )
}

export default CodeEditor;