import "./threeDots.module.css";

const ThreeDots = () => {
    return (
        <div className="threedots">
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default ThreeDots;